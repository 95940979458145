@import 'src/style/mixins';

.Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  position: relative;
  @include tablet {
    flex-direction: row;
  }
}

.Container::before {
  @include tablet {
    content: '';
    position: absolute;
    left: 0;
    width: 1px;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto 0;
    align-items: center;
    background-color: $color-black5;
  }
}

.ListHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 19px;
  font-size: 16px;
  font-weight: $fontWeights-medium;
  white-space: nowrap;
  justify-content: space-between;

  .Link {
    font-weight: $fontWeights-regular;
    font-size: 14px;
    color: $color-blue2;
    cursor: pointer;
    .Arrow {
      padding-left: 5px;
    }
  }
}

.List {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Active {
  color: $color-blue2;
  background-color: $color-blue3;
  svg {
    path {
      fill: $color-blue2;
      stroke: $color-blue2;
    }
  }
}
