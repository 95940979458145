@import "src/style/mixins";

.Container {
  display: none;

  @include tablet {
    display: block;
    position: relative;
  }

  .Desktop {
    position: absolute;
    display: none;
    width: fit-content;
    min-width: 100%;
    border: 1px solid $color-black3;
    background-color: $color-white;
    border-radius: 5px;
    top: 8px;

    @include tablet {
      display: flex;
    }
  }
}
.Mobile {
  display: block;
  visibility: visible;
  
  @include tablet {
    display: none;
    visibility: hidden;
  }
}

.ModalBox {
  max-height: 100vh;
  z-index: 30;
}

.DoneButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 12px;
  }
}

.CurrentCityMobile {
  margin: 10px 0px 10px 15px;
  h4 {
    padding: 0;
    margin: 0;
  }
}

.LeftSection {
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 5px;
  padding-bottom: 10px;
}

.RightSection {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 6;
}

.RightSection::before {
  content: "";
  position: absolute; 
  left: 0;
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  align-items: center;
  background-color: $color-black5;
}

.Link {
  margin-right: 4px;
}

.CustomMarketsAndComps {
  position: relative;
  display: flex;
  height: 100%;
}

.CustomMarketsAndComps::before {
  content: "";
  position: absolute; 
  width: 100%;
  height: 1px;
  top: 0;
  align-items: center;
  background-color: $color-black5;
}