@import "src/style/mixins";

.DropdownButton {
  width: 260px;
  @include tablet {
    width: 403px;
  }
}

.Container {
  position: absolute;
  background: white;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
}

.RelatedResultsWrapper {
  border-radius: 0px 0px 8px 8px;
}

.Table {
  table tbody,
  table thead {
    display: block;
    width: 100%;
    border-collapse: collapse;
  }


  table tbody {
    overflow: auto;
    max-height: calc(100vh - 250px);
    @include tablet {
      max-height: 300px;
    }
  }

  table {
    width: 100%;
    border-spacing: 0px;
  }

  th {
    text-align: center;
    padding: 17px;
    font-weight: $fontWeights-medium;
    background-color: $color-black6;
  }

  td {
    padding: 1px 0 4px;
    margin-bottom: 2px;
    margin-top: 2px;
    height: 40px;
    font-size: 13px;
  }

  th,
  td {
    padding: 10px 19px;
    min-width: 150px;
    width: 100%;
    font-size: 14px;
  }

  td:nth-last-child(1) {
    text-align: center;
  }

  th:nth-last-child(2) {
    text-align: left;
  }
}

th.TitleTable {
  font-weight: 100;
  color: $color-black2;
  span {
    color: $color-black0;
  }
}



.List {
  min-height: 40px;
  font-size: 13px;
  color: $color-black0;
  overflow: scroll;
  font-weight: 400;

  &:hover,
  :focus {
    text-decoration: none;
    color: $color-blue2;
    background-color: $color-blue3;
    cursor: pointer;
  }
}

.ListLabels {
  top: 0;
  z-index: 2;
  position: sticky;
}

.Link {
  display: none;
  @include tablet {
    display: block;
    border-top: 1px solid $color-black4;
    padding: 12px;
    color: $color-black2;
    text-align: center;
  }
}

.NotAreaMessage {
  height: 163px;
  text-align: center;
  display: block;
  padding: 37px 10px 35px 10px;

  span {
    font-weight: $fontWeights-bold;
    text-decoration: underline;
  }

  h3 {
    color: $color-black0;
    font-weight: $fontWeights-medium;
    margin: 0;
    font-size: 12px;
  }

  p {
    color: $color-black3;
    font-weight: $fontWeights-regular;
    margin: 2px;
    font-size: 12px;
  }

  @include tablet {
    p {
      font-size: 14px;
    }
    h3 {
      font-size: 14px;
    }
  }
}

.CloseIcon {
  align-items: center;
  background: $color-black6;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.Desktop {
  display: none;
  @include tablet {
    display: block;
  }
}

.Mobile {
  display: block;
  visibility: visible;
  @include tablet {
    display: none;
    visibility: hidden;
  }
}

.ModalBox {
  max-height: 100vh;
  z-index: 30;
}

.DoneButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 12px;
  }
}

.CurrentCityMobile {
  margin: 10px 0px 10px 15px;
  h4 {
    padding: 0;
    margin: 0;
  }
}

.RelatedResults {
  border-top: 1px solid #DDDEE1;
  font-weight: 100;
  padding-left: 18px;
  padding-top: 10px;
  color: $color-black3;
  font-size: 14px;
}

th.TitleTableLight {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;

  .Light {
    font-weight: 100;
    font-size: 14px;
    text-transform: none;
  }
}

.ListTitleTable {
  font-weight: 500;
  font-size: 14px;
}

.Icon {
  display: inline-block;
  padding-right: 5px;
}