@import 'src/style/mixins';

.Container {
  position: relative;
}

.Badge {
  position: absolute;
  z-index: 2;
  color: $color-white;
  font-weight: $fontWeights-medium;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Content {
  height: 20px;
  width: 20px;
  padding: 10px;
  top: -8px;
  border-radius: 50%;
  font-size: 10px;
  border: 1px solid #fff;
  &.TopRight {
    right: 0;
    transform: translateX(40%);
  }
  &.TopLeft {
    left: 0;
    transform: translateX(-40%);
  }
}

.NoContent {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  top: -4px;
  &.TopRight {
    right: 5px;
    top: 6px;
  }
  &.TopLeft {
    left: -3px;
  }
}

.Theme {
  &Red {
    background-color: $color-red2;
  }
  &Green {
    background-color: $color-green;
  }
}
