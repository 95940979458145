@import 'src/style/mixins';

.Text {
  color: $color-black0;
  font-size: 14px;
  font-weight: $fontWeights-light;
  transition: all ease-in 100ms;
}

.Suggestion {
  font-size: 14px;
  font-weight: $fontWeights-semiBold;
}

.TextContainer {
  background-color: $color-white;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  transition: all ease-in 150ms;
  white-space: nowrap;
  padding: 10px 25px 10px 25px;

  &.NotFoundContainer {
    background-color: transparent;
    padding: 6px 0px 8px;
  }
}

.Active {
  background-color: $color-blue3;
}

.Active:not(.DisabledItem) .Text {
  color: $color-blue1;
}

.TextContainer:not(.DisabledItem):hover {
  background-color: $color-blue3;
}

.TextContainer:not(.DisabledItem):hover .Text {
  color: $color-blue1;
}

.TextContainer.DisabledItem {
  cursor: default;
}

.LoadingTextContainer span:first-child {
  margin-right: 5px;
}

.LoadingContainer {
  align-items: center;

  .LogoContainer {
    margin-top: 10px;
  }
  .Text,
  .Moment,
  .LogoContainer {
    text-align: center;
  }
  .Text {
    font-weight: $fontWeights-semiBold;
    font-size: 14px;
  }
  .Moment {
    font-weight: $fontWeights-light;
    font-size: 12px;
  }
}

.NotFoundText {
  font-weight: $fontWeights-semiBold;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RequestArea {
  margin-top: 11px;
  line-height: 21px;
  white-space: initial;
  a {
    text-decoration: underline;
  }
}

.LogoNotFound {
  text-align: center;
  margin-top: 15px;
}

.Line1 {
  margin: 0 auto;
  padding: 0;
  color: $color-black0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
  width: 90%;
  white-space: initial;
}
.Line2 {
  margin: 0 auto;
  padding: 0 0 10px 0;
  color: $color-black0;
  text-align: center;
  font-size: 14px;
  font-weight: 100;
  display: block;
  word-break: break-word;
  white-space: initial;
  width: 90%;
}

.WrapperCenter {
  text-align: center;
}

.NotFoundSuggestionContainer {
  padding: 5px 20px;
}
