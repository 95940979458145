@import "src/style/mixins";

.Item {
  padding: 10px 19px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-black3;

  svg {
    path {
      fill: $color-black3;
      stroke-width: 1px;  
    }
  }

  &:hover {
    color: $color-blue2;
    background-color: $color-blue3;
    cursor: pointer;
    svg {
      path {
        fill: $color-blue2;
      }
    }
  } 
}