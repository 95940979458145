@import 'src/style/mixins';

.Container {
  display: flex;
  flex-direction: column;
}

.ListHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 19px;
  font-size: 16px;
  font-weight: $fontWeights-medium;

  .Link {
    font-weight: $fontWeights-regular;
    font-size: 13px;
    color: $color-blue2;
    cursor: pointer;
    .Arrow {
      padding-left: 5px;
    }
  }
}

.Active {
  color: $color-blue2;
  background-color: $color-blue3;
  svg {
    path {
      fill: $color-blue2;
    }
  }
}
