@import 'src/style/mixins';

.Item {
  padding: 8px 15px;
  font-size: 14px;
  color: #707070;
  font-weight: 100;
  cursor: pointer;
  transition: background-color 0.15s;
  background-color: $color-white;

  &:hover {
    background-color: $color-black7;
  }

  &.Active {
    color: $color-blue1;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: $color-blue1;
      left: 0;
      bottom: -1px;
    }
  }
}
