@import 'src/style/mixins';

.AutoComplete {
  overflow: visible;
  position: relative;
  z-index: 5;
  width: auto;
}

.AutoComplete .Input {
  // Delete when we remove this 'nearby_areas_within_search' toggle
  height: 55px;
  width: 100%;
  padding: 8px 45px 8px 20px;
  position: relative;
  font-size: 14px;
  z-index: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 100;
}

.AutoComplete2 .Input {
  height: 48px;
  width: 100%;
  padding: 8px 45px 8px 32px;
  position: relative;
  font-size: 14px;
  z-index: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 100;
  border-color: #dddee1;
}

.List {
  list-style: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: calc(100% - 3px);
  transition: border ease-in 205ms;
  background-color: $color-white;
  border-radius: 0 0 $borderRadius $borderRadius;
  border: 1px solid $color-borders;
  border-width: 0 1px 1px 1px;
  margin: 0;
  padding: 8px 0;

  line-height: 25px;
  overflow: hidden;
}

.Icon {
  // Delete when we remove this 'nearby_areas_within_search' toggle
  height: 22px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.SearchIcon {
  height: 16px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.IconContainer {
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  right: 8px;
  justify-content: center;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);

  @include tablet {
    height: 36px;
    width: 36px;
  }
}

.CloseIcon {
  background: $color-black6;
  @extend .IconContainer;
}

.NearbyAreasButton {
  @extend .IconContainer;
  &:hover {
    background: $color-black6;
    svg {
      path {
        stroke: $color-blue2;
        fill: $color-blue2;
      }
    }
  }
}

.PopOverBox {
  padding: 5px;
  text-align: center;
}

.EmptyList {
  background: white;
}

.PopOver {
  text-align: left;

  p {
    font-size: 14px;
  }
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.PopOverTitle {
  font-size: 12px;
  color: $color-black3;
}

.Bold {
  font-weight: 500;
}
