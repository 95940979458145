@import 'src/style/mixins';

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include tablet {
    flex-direction: row;
  }
}

.ListHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 19px;
  font-size: 16px;
  font-weight: $fontWeights-medium;
  white-space: nowrap;
  justify-content: space-between;

  .Link {
    font-weight: $fontWeights-regular;
    font-size: 14px;
    color: $color-blue2;
    cursor: pointer;
    .Arrow {
      padding-left: 5px;
    }
  }
}

.List {
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    svg {
      path {
        fill: transparent;
        stroke: $color-black3;
      }
    }

    &:hover {
      svg {
        path {
          fill: $color-blue2;
          stroke: $color-blue2;
        }
      }
    }
  }
}

.Active {
  color: $color-blue2;
  background-color: $color-blue3;
  svg {
    path {
      fill: $color-blue2 !important;
      stroke: $color-blue2 !important;
    }
  }
}
